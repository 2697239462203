<template>
<div>
  <div class="access">
    <img class="parking" src="../assets/s007a.webp">
    <h2 class="name_h2">ポテンザ S007a</h2>

    <p class="access_p">
    ポテンザ S007aはスポーティなクルマにおすすめなタイヤになります。<br>
    グリップ力に優れ、ドライ性能とウェット性能が高いタイヤです。<br>
    数々の世界的ハイパフォーマンスカーに認められたポテンザS007aを是非お試しください。
    </p>

    <div class="square-01">
    </div>

    <h3 class="recom_h3">推奨車種</h3>
    <p class="recom">セダン / スポーツ</p>

    <div class="charge2">
    <div class="charge_table">
      <h2 class="charge_table_h2"><span class="charge_table_span">サイズ表・メーカー希望小売価格</span><br>※4本購入で値引きできます</h2>
      <table class="biz-hour">
        <tbody>
          <tr>
            <th>サイズ</th>
            <th>税込<span class="price_span"> 1本</span></th>
          </tr>
          <tr>
            <td>21インチ</td>
          </tr>
          <tr>
            <td>285/30R21 100Y XL</td>
            <td class="reds"><span class="red_p">¥</span>114,180</td>
          </tr>
          <tr>
            <td>255/30R21 93Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>106,260</td>
          </tr>
          <tr>
            <td>245/35R21 96Y</td>
            <td class="reds"><span class="red_p">¥</span>99,440</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>20インチ</td>
          </tr>
          <tr>
            <td>285/25R20 93Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>118,580</td>
          </tr>
          <tr>
            <td>295/30R20 101Y XL</td>
            <td class="reds"><span class="red_p">¥</span>114,180</td>
          </tr>
          <tr>
            <td>285/30R20 99Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>111,650</td>
          </tr>
          <tr>
            <td>75/30R20 97Y XL</td>
            <td class="reds"><span class="red_p">¥</span>109,010</td>
          </tr>
          <tr>
            <td>255/30R20 92Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>103,730</td>
          </tr>
          <tr>
            <td>245/30R20 90Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>101,200</td>
          </tr>
          <tr>
            <td>235/30R20 88Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>98,670</td>
          </tr>
          <tr>
            <td>285/35R20 100Y</td>
            <td class="reds"><span class="red_p">¥</span>106,260</td>
          </tr>
          <tr>
            <td>275/35R20 102Y XL</td>
            <td class="reds"><span class="red_p">¥</span>103,730</td>
          </tr>
          <tr>
            <td>265/35R20 99Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>101,200</td>
          </tr>
          <tr>
            <td>255/35R20 97Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>99,440</td>
          </tr>
          <tr>
            <td>245/35R20 95Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>96,690</td>
          </tr>
          <tr>
            <td>255/40R20 101W XL</td>
            <td class="reds"><span class="red_p">¥</span>88,000</td>
          </tr>
          <tr>
            <td>245/40R20 99Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>86,350</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>19インチ</td>
          </tr>
          <tr>
            <td>285/30R19 98Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>93,940</td>
          </tr>
          <tr>
            <td>275/30R19 96Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>91,630</td>
          </tr>
          <tr>
            <td>265/30R19 93Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>86,680</td>
          </tr>
          <tr>
            <td>255/30R19 91Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>79,860</td>
          </tr>
          <tr>
            <td>285/35R19 99Y</td>
            <td class="reds"><span class="red_p">¥</span>88,220</td>
          </tr>
          <tr>
            <td>275/35R19 100Y XL</td>
            <td class="reds"><span class="red_p">¥</span>85,800</td>
          </tr>
          <tr>
            <td>265/35R19 98Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>83,160</td>
          </tr>
          <tr>
            <td>255/35R19 96Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>81,510</td>
          </tr>
          <tr>
            <td>245/35R19 93Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>79,090</td>
          </tr>
          <tr>
            <td>235/35R19 91Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>75,680</td>
          </tr>
          <tr>
            <td>225/35R19 88Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>72,380</td>
          </tr>
          <tr>
            <td>285/40R19 103Y</td>
            <td class="reds"><span class="red_p">¥</span>84,040</td>
          </tr>
          <tr>
            <td>275/40R19 105Y XL</td>
            <td class="reds"><span class="red_p">¥</span>82,280</td>
          </tr>
          <tr>
            <td>255/40R19 100Y XL</td>
            <td class="reds"><span class="red_p">¥</span>76,560</td>
          </tr>
          <tr>
            <td>245/40R19 98Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>75,020</td>
          </tr>
          <tr>
            <td>225/40R19 93Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>69,960</td>
          </tr>
          <tr>
            <td>245/45R19 102Y XL</td>
            <td class="reds"><span class="red_p">¥</span>68,310</td>
          </tr>
          <tr>
            <td>225/45R19 96Y</td>
            <td class="reds"><span class="red_p">¥</span>61,710</td>
          </tr>
          
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>18インチ</td>
          </tr>
          <tr>
            <td>285/35R18 101Y XL</td>
            <td class="reds"><span class="red_p">¥</span>87,230</td>
          </tr>
          <tr>
            <td>275/35R18 99Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>79,090</td>
          </tr>
          <tr>
            <td>265/35R18 97Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>76,560</td>
          </tr>
          <tr>
            <td>255/35R18 94Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>72,380</td>
          </tr>
          <tr>
            <td>245/35R18 92Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>74,800</td>
          </tr>
          <tr>
            <td>225/35R18 83Y</td>
            <td class="reds"><span class="red_p">¥</span>72,820</td>
          </tr>
          <tr>
            <td>275/40R18 103Y XL</td>
            <td class="reds"><span class="red_p">¥</span>81,950</td>
          </tr>
          <tr>
            <td>265/40R18 101Y XL</td>
            <td class="reds"><span class="red_p">¥</span>79,970</td>
          </tr>
          <tr>
            <td>255/40R18 99Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>76,450</td>
          </tr>
          <tr>
            <td>245/40R18 97Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>67,540</td>
          </tr>
          <tr>
            <td>235/40R18 95Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>64,020</td>
          </tr>
          <tr>
            <td>225/40R18 92Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>62,920</td>
          </tr>
          <tr>
            <td>215/40R18 89Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>62,370</td>
          </tr>
          <tr>
            <td>255/45R18 103Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>66,440</td>
          </tr>
          <tr>
            <td>245/45R18 100Y XL</td>
            <td class="reds"><span class="red_p">¥</span>63,250</td>
          </tr>
          <tr>
            <td>235/45R18 98Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>60,060</td>
          </tr>
          <tr>
            <td>225/45R18 95Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>56,760</td>
          </tr>
          <tr>
            <td>215/45R18 93Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>52,140</td>
          </tr>
          <tr>
            <td>235/50R18 101Y XL</td>
            <td class="reds"><span class="red_p">¥</span>50,270</td>
          </tr>
          <tr>
            <td>225/50R18 95W</td>
            <td class="reds"><span class="red_p">¥</span>48,620</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>17インチ</td>
          </tr>
          <tr>
            <td>255/40R17 98Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>57,640</td>
          </tr>
          <tr>
            <td>245/40R17 91Y/td>
            <td class="reds"><span class="red_p">¥</span>54,780</td>
          </tr>
          <tr>
            <td>235/40R17 90Y</td>
            <td class="reds"><span class="red_p">¥</span>52,470</td>
          </tr>
          <tr>
            <td>215/40R17 83Y</td>
            <td class="reds"><span class="red_p">¥</span>48,070</td>
          </tr>
          <tr>
            <td>205/40R17 84Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>49,720</td>
          </tr>
          <tr>
            <td>245/45R17 99Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>58,520</td>
          </tr>
          <tr>
            <td>235/45R17 97Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>51,700</td>
          </tr>
          <tr>
            <td>225/45R17 94Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>48,730</td>
          </tr>
          <tr>
            <td>215/45R17 91Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>45,650</td>
          </tr>
          <tr>
            <td>205/45R17 88Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>45,650</td>
          </tr>
          <tr>
            <td>235/50R17 96Y</td>
            <td class="reds"><span class="red_p">¥</span>46,310</td>
          </tr>
          <tr>
            <td>225/50R17 98Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>45,430</td>
          </tr>
          <tr>
            <td>215/50R17 91W</td>
            <td class="reds"><span class="red_p">¥</span>43,120</td>
          </tr>
          <tr>
            <td>205/50R17 93Y	XL</td>
            <td class="reds"><span class="red_p">¥</span>39,050</td>
          </tr>
          <tr>
            <td>225/55R17 101Y XL</td>
            <td class="reds"><span class="red_p">¥</span>43,780</td>
          </tr>
          <tr>
            <td>215/55R17 94W</td>
            <td class="reds"><span class="red_p">¥</span>41,360</td>
          </tr>
          <tr>
            <td>205/55R17 91Y</td>
            <td class="reds"><span class="red_p">¥</span>39,930</td>
          </tr>
          <tr>
            <td></td>
          </tr>
          <tr>
            <td>16インチ</td>
          </tr>
          <tr>
            <td>225/50R16 96W	XL</td>
            <td class="reds"><span class="red_p">¥</span>38,940</td>
          </tr>
          <tr>
            <td>205/50R16 87V</td>
            <td class="reds"><span class="red_p">¥</span>34,320</td>
          </tr>
          <tr>
            <td>205/55R16 94W	XL</td>
            <td class="reds"><span class="red_p">¥</span>34,100</td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>

    <CarouselsSedan/>

    <p class="about_p">ACCESS</p>
    <h1>勝間田タイヤ</h1>
    <p class="address_p">静岡県御殿場市茱萸沢241-1</p>
    <a href="https://goo.gl/maps/oabDqVGbjJQn65yk8">Google Maps</a>
  </div>
  <div>
    <Footer/>
  </div>
</div>
</template>

<script>
import CarouselsSedan from '@/components/CarouselsSedan.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'HomeView',
  components: {
    CarouselsSedan,
    Footer
  }
}
</script>

<style scoped>
.recom_h3{
  text-align: left;
}
.recom{
  padding-bottom: 3rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
}
.recom_list{
  padding-bottom: 1rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
}
.recom_list2{
  padding-bottom: 6rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
}
.access{
  padding-top: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 6rem;
}
.access h1{
  margin-top: 0.8rem;
  padding-bottom: 0.5rem;
  text-align:left;
  font-size: 1.9rem;
}
.access p{
  text-align:left;
}
.access_p{
  padding-bottom: 2rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.7rem;
}

.access a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  width: 160px;
  height: 50px;
  color: #333;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
  background-color: #9099a2;
  border-radius: 40px;
  margin-top: 30px;
}
.access a::after {
  content: '';
  width: 10px;
  height: 10px;
  border-top: 2px solid #d5d5d5;
  border-right: 2px solid #d5d5d5;
  transform: rotate(45deg);
}

.name_h2{
  padding-top: 1rem;
  font-size: 1.5rem;
  text-align: left;
  padding-bottom: 2rem;
}

.about_p{
  margin-top: -4rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
  padding-top: 4rem;
}
.address_p{
  font-weight: 600;
  font-size: 0.8rem;
}

nav {
  margin-top: -7rem;
  padding-bottom: 6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 0.8rem;
  text-align:left;
  line-height: 1.6rem;
}

nav a {
  font-weight: bold;
  text-decoration: none;
  color: #2c3e50;
}
nav a.router-link-exact-active {
  color: #42b983;
}
nav h3{
  font-size: 1.5rem;
}



.access img {
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 1rem;
}

@media (max-width: 640px)
.page .moretodiscover .square-01 {
    width: 67.5vw;
    height: calc(100% - 25vw);
    position: absolute;
    top: 25vw;
    right: 0;
    background-color: #dae4e8;
}
.charge_table_h2{
  text-align: left;
}
.charge_table_span{
  font-size: 1rem;
}
.price_span{
  font-size: 0.8rem;
}
.charge_table{
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.biz-hour {
  border-collapse: collapse;
}
@media screen and (max-width: 414px) {
  .biz-hour {/* スマホのときだけ親要素いっぱいに */
    width: 100%;
  }
}

.biz-hour th, .biz-hour td {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  /* ２番目以降の線 */
  /* 色はお好みで */
  border-bottom: 1px solid black;
  font-weight: bold;
}

/* 上段の背景色 */
.biz-hour th {
  /* background: black; */
  font-weight: bold;
}

/* 土曜日の文字色 */
.biz-hour th.sat {
  color: #3db2da;
}

/* 日曜日の文字色 */
.biz-hour th.sun {
  color: #e66a6a;
}
</style>
